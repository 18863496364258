import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/auth.context";

export const MainLogo = ({ className, href }) => {
  const navigate = useNavigate();
  const { user, setUser, global, setGlobal } = useAuth();

  // 메인 홈
  const onHome = () => {
    // 카테고리 정보 클리어
    setGlobal((global) => ({
      ...global,
      menuCategoryLevel: 0,
      menuCategoryCode: 'All',
      menuCategoryName: "전체",
      submenuCategoryCode: '',
      submenuCategoryName: ''      
    }));
    navigate("/");
  };

  return (
    <div className={`main-logo ${className}`}>
      <div style={{cursor:'pointer'}} onClick={ () => onHome() }>
        <img className="link-11" alt="Link" src="/img/link-1.png" />
      </div>
    </div>
  );
};

MainLogo.propTypes = {
  href: PropTypes.string,
};
