import React from "react";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { FaRegStar, FaRegHeart } from 'react-icons/fa6'
import { FaChevronRight } from 'react-icons/fa6'
import { useNavigate } from "react-router-dom";

export const LinkTree = ({ className }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();

  // 메인 홈
  const onClick = (paramLevel, paramCode, paramName) => {
    if (codeParam === "All") {
        setGlobal((global) => ({
          ...global,
          menuCategoryLevel: 0,
          menuCategoryCode: 'All',
          menuCategoryName: "전체",
          submenuCategoryCode: '',
          submenuCategoryName: ''
        }));
        navigate("/");
    } else {
      setGlobal((global) => ({
        ...global,
        menuCategoryLevel: paramLevel,
        menuCategoryCode: paramCode,
        menuCategoryName: paramName,
        submenuCategoryCode: '',
        submenuCategoryName: ''
      }));
      navigate(`/${codeParam}`);
    }

  };

  return (
    <div className={`link-tree ${className}`}>
      <div className="item-link" style={{cursor:'pointer'}} onClick={ () => onClick(0, 'All', '전체') }>
        <div className="symbol-6"></div>
      </div>

      <div className="item-link">
        <div className="symbol-6"><FaChevronRight size="9px"/></div>
      </div>

      <div className="item-link" style={{cursor:'pointer'}} onClick={ () => onClick(1, global.menuCategoryCode, global.menuCategoryName) }>
        <div className="symbol-6">{global.menuCategoryName}</div>
      </div>

      <div className="item-link">
        <div className="symbol-6">{global.submenuCategoryName && global.submenuCategoryName.length > 0 ? <FaChevronRight size="9px"/> : null}</div>
      </div>

      <div className="item-link">
        <div className="symbol-6">{global.submenuCategoryName && global.submenuCategoryName.length > 0 ? global.submenuCategoryName : null}</div>
      </div>
    </div>
  );
};
