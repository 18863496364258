import { useParams } from "react-router";
import { ProductDetailContainer } from "../../containers/ProductDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";

export const ProductDetailPage = () => {
  const { id } = useParams();  

  // // 기존에 설정된 카테고리 클리어
  // useEffect(() => {
  //   if (category === 'All') {
  //     setGlobal((global) => ({
  //       ...global,
  //       menuCategoryLevel: 0,
  //       menuCategoryCode: 'All',
  //       menuCategoryName: "전체",
  //       submenuCategoryCode: '',
  //       submenuCategoryName: ''  
  //       order: 'best_desc',
  //     }));
  //   }
  // }, []);

  return (
    (!isNaN(+id) === true) ?
    <ProductDetailContainer id={parseInt(id)} /> :
    <ProductListContainer/>
  );
}
