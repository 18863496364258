/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";

export const MessagePanel = ({ className, code, detail }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();

  const onHome = () => {
    // 카테고리 정보 클리어
    setGlobal((global) => ({
      ...global,
      menuCategoryLevel: 0,
      menuCategoryCode: 'All',
      menuCategoryName: "전체",
      submenuCategoryCode: '',
      submenuCategoryName: ''      
    }));
    navigate("/");
  }

  const onLogin = () => {
    if (user && user.access.length >= 100) {
      AuthApi.Logout(user.access);
    }
    navigate("/login");
  }


  return (
    <div className={`message-panel ${className}`} code={code} detail={detail}>
      <div className="MPB">
        <div className="message-body">
          <p className="message-body-text"><p>Code : {code}</p><p>Detail : {detail}</p>
          </p>
        </div>
        <button className="button">
          <div className="button-box" style={{cursor: 'pointer'}} onClick={ () => onHome() }>홈으로</div>
        </button>
        <button className="button-box-wrapper">
          <div className="button-box-2" style={{cursor: 'pointer'}} onClick={ () => onLogin() }>로그인</div>
        </button>
        <div className="message-title">
          <div className="message-title-text">Error</div>
        </div>
        <img className="message-logo" alt="Message logo" src="/img/messagelogo.png" />
      </div>
    </div>
  );
};
